import React from 'react'
import {graphql, Link} from 'gatsby'
import get from 'lodash/get'

import Seo from '../components/seo'
import Layout from '../components/layout'
import Hero from '../components/hero'
import * as styles from './blog-post.module.css'
import DonateButton from "../components/donate-button";

class BlogPostTemplate extends React.Component {
    render() {
        const post = get(this.props, 'data.contentfulBlogPost')
        const previous = get(this.props, 'data.previous')
        const next = get(this.props, 'data.next')

        return (
            <Layout location={this.props.location}>
                <Seo
                    title={post.title}
                    description={post.description.childMarkdownRemark.excerpt}
                    image={`http:${post.heroImage.resize.src}`}
                />
                <Hero
                    image={post.heroImage?.gatsbyImageData}
                    title={post.title}
                />
                <div className={styles.container}>
          <span className={styles.meta}>
            {post.body?.childMarkdownRemark?.timeToRead} minute read
          </span>
                    <div className={styles.article}>
                        <div
                            className={styles.body}
                            dangerouslySetInnerHTML={{
                                __html: post.body?.childMarkdownRemark?.html,
                            }}
                        />
                        <div className={styles.buttonContainer}>
                            <DonateButton buttontext="LEUK BLOG? DONEER 🖤"/>
                        </div>
                        {(previous || next) && (
                            <nav>
                                <ul className={styles.articleNavigation}>
                                    {previous && (
                                        <li>
                                            <Link to={`/blog/${previous.slug}`} rel="prev">
                                                ← {previous.title}
                                            </Link>
                                        </li>
                                    )}
                                    {next && (
                                        <li>
                                            <Link to={`/blog/${next.slug}`} rel="next">
                                                {next.title} →
                                            </Link>
                                        </li>
                                    )}
                                </ul>
                            </nav>
                        )}
                    </div>
                </div>
            </Layout>
        )
    }
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug(
    $slug: String!
    $previousPostSlug: String
    $nextPostSlug: String
  ) {
    contentfulBlogPost(slug: { eq: $slug }) {
      slug
      title
      publishDate(formatString: "MMMM Do, YYYY")
      rawDate: publishDate
      heroImage {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED, width: 1280)
        resize(height: 630, width: 1200) {
          src
        }
      }
      body {
        childMarkdownRemark {
          html
          timeToRead
        }
      }
      blogCategory {
        category
      }
      description {
        childMarkdownRemark {
          excerpt
        }
      }
    }
    previous: contentfulBlogPost(slug: { eq: $previousPostSlug }) {
      slug
      title
    }
    next: contentfulBlogPost(slug: { eq: $nextPostSlug }) {
      slug
      title
    }
  }
`
